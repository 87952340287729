define("workflows-web/components/form-field-input/number/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aHIAElCo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-u-vertically-centered-container\"],[12],[2,\"\\n  \"],[1,[30,[36,8],null,[[\"type\",\"value\",\"focus-out\",\"class\",\"data-test-id\",\"classNames\",\"min\",\"max\"],[\"number\",[35,0,[\"value\"]],[30,[36,7],[[32,0],\"onFocusOut\"],null],[30,[36,6],[[30,[36,6],[\"hf-form-field-input hf-number-input\",[35,2]],null],\" \",[30,[36,5],[[35,4],\"hf-is-error\"],null],\" \"],null],[35,3],[35,2],[35,0,[\"minValue\"]],[35,0,[\"maxValue\"]]]]]],[2,\"\\n\\n\"],[6,[37,5],[[35,0,[\"unitsLabel\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[35,0,[\"unitsLabel\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,9],[[35,0,[\"hideErrors\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[19,\"components/form-field-input/partials/error-message\",[]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"field\",\"t\",\"inputClass\",\"fieldDataTestId\",\"hasErrors\",\"if\",\"concat\",\"action\",\"input\",\"unless\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/number/template.hbs"
    }
  });
});