define("workflows-web/workflows/mixins/workflows/workflow-actions", ["exports", "workflows-web/constants/editor", "workflows-web/workflows/details/edit/utilities"], function (_exports, _editor, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    requestService: Ember.inject.service('request'),
    intlService: Ember.inject.service('intl'),
    accountInfoService: Ember.inject.service('account-info'),
    formsService: Ember.inject.service('forms'),
    getWorkflowsActions: function getWorkflowsActions() {
      var _this = this;
      var actionClass = Ember.Object.extend({
        name: '',
        apiUrl: '',
        rawFields: null
      });
      var workflowActionChoices = Ember.A();
      var extractValuesFromTextActionFields = actionClass.create({
        stepType: 'extract_values_from_text',
        application: 'workflows',
        color: '#2b552c',
        component: 'workflows/details/edit/actions/details/extract-values-from-text',
        rawFields: Ember.A([Ember.Object.create({
          label: 'Field',
          key: 'field',
          isDynamicField: true,
          isMandatory: true,
          isPrimary: true,
          labelPartial: 'workflows/details/edit/actions/details/primary-field-label',
          step: 1
        }), Ember.Object.create({
          label: 'Pattern',
          key: 'pattern',
          isDynamicField: true,
          step: 2
        }), Ember.Object.create({
          key: 'alternatePatterns',
          isArray: true,
          step: 3,
          childFieldMetaInfo: Ember.Object.create({
            key: 'alternatePattern',
            isObject: true,
            childFieldsMetaInfo: Ember.A([Ember.Object.create({
              key: 'pattern',
              isDynamicField: true,
              isMandatory: true
            })])
          })
        })])
      });
      var platforms = Ember.A([Ember.Object.create({
        'name': 'email',
        'displayName': 'Email',
        'isEnabled': true
      }), Ember.Object.create({
        'name': 'slack',
        'displayName': 'Slack',
        'isEnabled': false
      }), Ember.Object.create({
        'name': 'ms_teams',
        'displayName': 'MS Teams',
        'isEnabled': false
      })]);
      var requestApprovalActionFields = actionClass.create({
        stepType: 'request_approval',
        application: 'workflows',
        color: '#2b552c',
        component: 'workflows/details/edit/actions/details/request-approval',
        rawFields: Ember.A([Ember.Object.create({
          key: 'platforms',
          isMultiChoice: true,
          isInline: true,
          valueProperty: 'name',
          labelProperty: 'displayName',
          label: 'Platforms',
          value: platforms.filterBy('isEnabled'),
          options: platforms,
          alwaysSendInPayload: true
        }), Ember.Object.create({
          label: 'Approvers',
          key: 'approvers',
          isDynamicField: true,
          isMandatory: true,
          labelHelpText: 'Separate multiple approver email addresses with comma'
        }), Ember.Object.create({
          label: 'Approval Required From',
          key: 'approvalRequiredFrom',
          isChoice: true,
          isMandatory: true,
          choiceLabelKey: 'label',
          choiceValueKey: 'value',
          choices: Ember.A([Ember.Object.create({
            'value': 'everyone',
            'label': 'Everyone'
          }), Ember.Object.create({
            'value': 'majority',
            'label': 'Majority'
          }), Ember.Object.create({
            'value': 'any',
            'label': 'Anyone'
          }), Ember.Object.create({
            'value': 'first_responder',
            'label': 'First Responder'
          })])
        }), Ember.Object.create({
          label: 'Subject',
          key: 'subject',
          isDynamicField: true,
          isMandatory: true,
          value: 'Request Approval For'
        }), Ember.Object.create({
          label: 'Message',
          key: 'message',
          isEditor: true,
          editorId: 'message',
          editorConfig: _editor.LITE_EDITOR_CONFIG,
          component: 'workflows/details/edit/actions/details/message-field',
          isMandatory: true,
          labelHelpText: '<<approve>> and <<decline>> in the message template are placeholders for buttons. If they are removed when configuring the message, the two buttons will be added at the end of the message.',
          value: "<div style=\"margin: 15px 0 0 10px; overflow: visible; width: 570px;\">\n        <h3 style=\"margin: 0 0 8px 0; padding: 0px;\">Request Approval For&nbsp;</h3>\n\n        <div style=\"margin: 6px 0 8px; padding: 0; background-color: #333;\n        height: 4px; line-height: 4px; font-size: 4px;\">&nbsp;</div>\n        Hi,<br />\n        <br />\n        A new request has been submitted for your approval. Please go through the request and confirm your approval.<br />\n        &nbsp;\n        <p><strong>Ticket Details</strong><br />\n        &nbsp;</p>\n\n        <div>\n        <p><em style=\"color:#666666; font-style:italic\">Ticket Id:</em>&nbsp;</p>\n\n        <p><em style=\"color:#666666; font-style:italic\">Ticket Url:</em>&nbsp;</p>\n\n        <p><em style=\"color:#666666; font-style:italic\">Ticket Subject:</em>&nbsp;</p>\n\n        <p><em style=\"color:#666666; font-style:italic\">Contact Name:</em>&nbsp;</p>\n\n        <p><em style=\"color:#666666; font-style:italic\">Contact Email:</em>&nbsp;<br />\n        &nbsp;</p>\n\n        <p>&lt;&lt;approve_button&gt;&gt;&nbsp; &nbsp; &lt;&lt;decline_button&gt;&gt;<br />\n        &nbsp;</p>\n        </div>\n\n        <p><span>Regards,</span></p>\n        </div>"
        }), Ember.Object.create({
          label: 'Attachments',
          key: 'attachments',
          isDynamicField: true,
          transformValue: _utilities.generateAttachmentsPayload
        })]),
        groups: Ember.A([Ember.Object.create({
          name: 'Approved',
          description: 'Execute the following set of actions upon approval of request'
        }), Ember.Object.create({
          name: 'Declined',
          description: 'Execute the following set of actions upon decline of request'
        })])
      });
      var sendEmailActionFields = actionClass.create({
        stepType: 'send_email',
        displayName: 'Send Email',
        application: 'workflows',
        color: '#166cd6',
        component: 'workflows/details/edit/actions/details/send-email',
        rawFields: Ember.A([Ember.Object.create({
          label: 'From Name',
          key: 'fromName',
          isDynamicField: true,
          isMandatory: true
        }), Ember.Object.create({
          label: 'To',
          key: 'to',
          isDynamicField: true,
          isMandatory: true
        }), Ember.Object.create({
          label: 'Cc',
          key: 'cc',
          labelHelpText: "Separate multiple cc's with comma",
          isDynamicField: true
        }), Ember.Object.create({
          label: 'Bcc',
          key: 'bcc',
          labelHelpText: "Separate multiple bcc's with comma",
          isDynamicField: true
        }), Ember.Object.create({
          label: 'Include user action in Email',
          key: 'includeEmailActions',
          isCheckBox: true,
          value: false
        }), Ember.Object.create({
          key: 'choices',
          isArray: true,
          primaryObjectKey: 'id',
          secondaryObjectKey: 'name',
          alwaysSendInPayload: true,
          childFieldMetaInfo: Ember.Object.create({
            key: 'choice',
            isObject: true,
            childFieldsMetaInfo: Ember.A([Ember.Object.create({
              label: 'Choice ID',
              key: 'id',
              isText: true
            }), Ember.Object.create({
              key: 'name',
              label: 'Name',
              isDynamicField: true,
              isMandatory: true
            }), Ember.Object.create({
              label: 'Type',
              key: 'type',
              isChoice: true,
              choiceLabelKey: 'label',
              choiceValueKey: 'value',
              choices: Ember.A([Ember.Object.create({
                'value': 'message',
                'label': 'Request for choice selection'
              }), Ember.Object.create({
                'value': 'form',
                'label': 'Request form submission'
              })])
            }), Ember.Object.create({
              key: 'settings',
              isObject: true,
              childFieldsMetaInfo: Ember.A([Ember.Object.create({
                key: 'message',
                label: 'Confirmation message',
                isDynamicField: true
              }), Ember.Object.create({
                key: 'form',
                label: 'Form',
                isChoice: true,
                choiceLabelKey: 'label',
                choiceValueKey: 'value',
                choices: this.formsService.formChoices
              })])
            })])
          })
        }), Ember.Object.create({
          label: 'Subject',
          key: 'subject',
          isDynamicField: true,
          isMandatory: true
        }), Ember.Object.create({
          label: 'Message',
          key: 'message',
          isEditor: true,
          editorId: 'message',
          editorConfig: _editor.LITE_EDITOR_CONFIG,
          isMandatory: true
        }), Ember.Object.create({
          label: 'Attachments',
          key: 'attachments',
          transformValue: _utilities.generateAttachmentsPayload,
          isDynamicField: true
        }), Ember.Object.create({
          key: 'metadata',
          isArray: true,
          childFieldMetaInfo: Ember.Object.create({
            key: 'metadata',
            isObject: true,
            childFieldsMetaInfo: Ember.A([Ember.Object.create({
              label: 'Key',
              key: 'key',
              isText: true,
              isMandatory: true
            }), Ember.Object.create({
              key: 'value',
              label: 'Value',
              isDynamicField: true,
              isMandatory: true
            })])
          })
        })])
      });
      var checkConditionFields = actionClass.create({
        stepType: 'check_conditions',
        displayName: 'Check Conditions',
        application: 'workflows',
        color: '#1b332d',
        component: 'workflows/details/edit/actions/details/check-conditions',
        rawFields: Ember.A([Ember.Object.create({
          label: 'Id',
          key: 'id',
          isDynamicField: true,
          alwaysSendInPayload: true
        }), Ember.Object.create({
          label: 'Name',
          key: 'name',
          isText: true,
          isMandatory: true,
          alwaysSendInPayload: true
        }), Ember.Object.create({
          label: 'Description',
          key: 'description',
          isDynamicField: true,
          isMandatory: false,
          alwaysSendInPayload: true
        }), Ember.Object.create({
          key: 'matchAll',
          isArray: true,
          isMandatory: false,
          alwaysSendInPayload: true,
          childFieldMetaInfo: Ember.Object.create({
            isCondition: true,
            conditionFields: Ember.A(),
            conditionComponent: 'filter-condition',
            addConditionComponent: 'add-filter-condition'
          })
        }), Ember.Object.create({
          key: 'matchAny',
          isArray: true,
          isMandatory: false,
          alwaysSendInPayload: true,
          childFieldMetaInfo: Ember.Object.create({
            isCondition: true,
            conditionFields: Ember.A(),
            conditionComponent: 'filter-condition',
            addConditionComponent: 'add-filter-condition'
          })
        }), Ember.Object.create({
          key: 'isDefault',
          isCheckBox: true,
          label: 'Default ?',
          alwaysSendInPayload: true
        })])
      });
      var httpRequestActionFields = actionClass.create({
        stepType: 'http_request',
        application: 'workflows',
        color: '#bf350c',
        component: 'workflows/details/edit/actions/details/http-request',
        rawFields: Ember.A([Ember.Object.create({
          label: 'URL',
          key: 'url',
          isDynamicField: true,
          isMandatory: true,
          isPrimary: true,
          labelPartial: 'workflows/details/edit/actions/details/primary-field-label',
          step: 1
        }), Ember.Object.create({
          label: 'HTTP Method',
          key: 'method',
          step: 2,
          isChoice: true,
          choiceLabelKey: 'label',
          choiceValueKey: 'value',
          choices: Ember.A([Ember.Object.create({
            'value': 'get',
            'label': 'GET'
          }), Ember.Object.create({
            'value': 'post',
            'label': 'POST'
          }), Ember.Object.create({
            'value': 'patch',
            'label': 'PATCH'
          }), Ember.Object.create({
            'value': 'put',
            'label': 'PUT'
          }), Ember.Object.create({
            'value': 'delete',
            'label': 'DELETE'
          })])
        }), Ember.Object.create({
          label: 'Headers',
          key: 'headers',
          isCodeEditor: true,
          mode: 'javascript',
          step: 2,
          labelHelpText: true,
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextExample: {
            'Content-Type': 'application/json'
          },
          labelHelpTextPartial: 'workflows/details/edit/actions/details/http-request/label-help-text'
        }), Ember.Object.create({
          label: 'Request Body',
          key: 'body',
          isCodeEditor: true,
          mode: 'javascript',
          step: 2,
          labelHelpText: true,
          usePopOverNewTooltipForHelpText: true,
          labelHelpTextExample: {
            'name': 'John Doe',
            'age': 28
          },
          labelHelpTextPartial: 'workflows/details/edit/actions/details/http-request/label-help-text'
        })])
      });
      var getTagsAndCategories = actionClass.create({
        stepType: 'auto_tagging_and_categorization',
        application: 'workflows',
        color: '#5244b3',
        stepIcon: 'ai',
        stepTitle: this.intlService.findTranslationByKey('ai-action-text'),
        rawFields: Ember.A([Ember.Object.create({
          label: 'Field',
          key: 'description',
          helperText: this.intlService.findTranslationByKey('auto_tagging_and_categorization-helper-text'),
          labelPartial: 'workflows/details/edit/actions/details/primary-field-label',
          isDynamicField: true,
          isMandatory: true
        })])
      });
      var summarizeText = actionClass.create({
        stepType: 'summarize_text',
        application: 'workflows',
        color: '#501115',
        stepIcon: 'ai',
        stepTitle: this.intlService.findTranslationByKey('ai-action-text'),
        rawFields: Ember.A([Ember.Object.create({
          label: 'Field',
          key: 'text',
          helperText: this.intlService.findTranslationByKey('summarize_text-helper-text'),
          labelPartial: 'workflows/details/edit/actions/details/primary-field-label',
          isDynamicField: true,
          isMandatory: true
        })])
      });
      var expandText = actionClass.create({
        stepType: 'expand_text',
        application: 'workflows',
        color: '#4e1150',
        stepIcon: 'ai',
        stepTitle: this.intlService.findTranslationByKey('ai-action-text'),
        rawFields: Ember.A([Ember.Object.create({
          label: 'Field',
          key: 'text',
          labelPartial: 'workflows/details/edit/actions/details/primary-field-label',
          isDynamicField: true,
          helperText: this.intlService.findTranslationByKey('expand_text-helper-text'),
          isMandatory: true
        })])
      });
      var rephraseText = actionClass.create({
        stepType: 'rephrase_text',
        application: 'workflows',
        color: '#115044',
        stepIcon: 'ai',
        stepTitle: this.intlService.findTranslationByKey('ai-action-text'),
        rawFields: Ember.A([Ember.Object.create({
          label: 'Field',
          key: 'text',
          helperText: this.intlService.findTranslationByKey('rephrase_text-helper-text'),
          labelPartial: 'workflows/details/edit/actions/details/primary-field-label',
          isDynamicField: true,
          isMandatory: true
        }), Ember.Object.create({
          label: 'Format',
          key: 'format',
          isChoice: true,
          isMandatory: true,
          choiceLabelKey: 'label',
          choiceValueKey: 'value',
          choices: Ember.A([Ember.Object.create({
            value: 'formal',
            label: this.intlService.findTranslationByKey('rephrase_text-formal')
          }), Ember.Object.create({
            value: 'informal',
            label: this.intlService.findTranslationByKey('rephrase_text-informal')
          }), Ember.Object.create({
            value: 'concise',
            label: this.intlService.findTranslationByKey('rephrase_text-concise')
          })])
        })])
      });
      var redactText = actionClass.create({
        stepType: 'redact_text',
        application: 'workflows',
        color: '#826044',
        stepIcon: 'ai',
        stepTitle: this.intlService.findTranslationByKey('ai-action-text'),
        rawFields: Ember.A([Ember.Object.create({
          label: 'Field',
          key: 'text',
          helperText: this.intlService.findTranslationByKey('redact_text-helper-text'),
          labelPartial: 'workflows/details/edit/actions/details/primary-field-label',
          isDynamicField: true,
          isMandatory: true
        }), Ember.Object.create({
          label: 'PII Fields to Redact',
          key: 'keysToRedact',
          isRemoteMultiChoiceDropdown: true,
          lookupUrl: 'entity-autocomplete/workflows/pii-keys/',
          searchParam: 'text',
          choiceLabelKey: 'name',
          choices: [],
          labelHelpText: 'Choose the PII fields to redact from the text'
        })])
      });
      var transformText = actionClass.create({
        stepType: 'transform_text',
        application: 'workflows',
        rawFields: Ember.A([])
      });
      var mathOperations = actionClass.create({
        stepType: 'math_operations',
        application: 'workflows',
        rawFields: Ember.A([])
      });
      var inlineLookupTable = actionClass.create({
        stepType: 'inline_lookup_table',
        application: 'workflows',
        rawFields: Ember.A([])
      });
      var dateFormatter = actionClass.create({
        stepType: 'date_formatter',
        application: 'workflows',
        rawFields: Ember.A([])
      });
      var delayAction = actionClass.create({
        stepType: 'delay',
        application: 'workflows',
        rawFields: Ember.A([])
      });
      var testEditor = actionClass.create({
        stepType: 'test_editor',
        application: 'workflows',
        color: '#501115',
        rawFields: Ember.A([])
      });
      var sendEmailReminder = actionClass.create({
        stepType: 'send_email_action_reminder',
        application: 'workflows',
        color: '#501115',
        rawFields: Ember.A([])
      });
      var generateFormLink = actionClass.create({
        stepType: 'generate_form_link',
        application: 'workflows',
        color: '#501115',
        rawFields: Ember.A([])
      });
      workflowActionChoices.addObjects([sendEmailActionFields, httpRequestActionFields, transformText, mathOperations, dateFormatter]);
      if (this.accountInfoService.sendEmailActionReminderEnabled) {
        workflowActionChoices.addObject(sendEmailReminder);
      }
      if (this.accountInfoService.generateFormLinkEnabled) {
        workflowActionChoices.addObject(generateFormLink);
      }
      if (this.accountInfoService.isExtractValuesFromTextEnabled) {
        workflowActionChoices.addObject(extractValuesFromTextActionFields);
      }
      if (this.accountInfoService.isCheckConditionFieldsEnabled) {
        workflowActionChoices.addObject(checkConditionFields);
      }
      if (this.accountInfoService.isApprovalManagementEnabled) {
        workflowActionChoices.addObject(requestApprovalActionFields);
      }
      if (this.accountInfoService.isAiActionsEnabled) {
        workflowActionChoices.addObjects([getTagsAndCategories, summarizeText, expandText, rephraseText, redactText]);
      }
      if (this.accountInfoService.isInlineLookupTableEnabled) {
        workflowActionChoices.addObject(inlineLookupTable);
      }
      if (this.accountInfoService.isDelayActionEnabled) {
        workflowActionChoices.addObject(delayAction);
      }
      if (this.accountInfoService.enableTestEditor) {
        workflowActionChoices.addObject(testEditor);
      }
      workflowActionChoices.forEach(function (action) {
        action.displayName = _this.intlService.findTranslationByKey(action.stepType);
        action.eventType = 'action';
      });
      return workflowActionChoices;
    }
  });
});