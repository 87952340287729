define("workflows-web/workflows/mixins/ms-teams/actions", ["exports", "workflows-web/constants/editor", "workflows-web/utilities/transform-data"], function (_exports, _editor, _transformData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    requestService: Ember.inject.service('request'),
    intlService: Ember.inject.service('intl'),
    formsService: Ember.inject.service('forms'),
    getMSTeamsActions: function getMSTeamsActions() {
      var _this = this;
      var actionClass = Ember.Object.extend({
        name: '',
        apiUrl: '',
        rawFields: null
      });
      var workflowActionChoices = Ember.A();
      var sendChannelMessageFields = actionClass.create({
        stepType: 'send_channel_message',
        application: 'ms-teams',
        color: '#444791',
        component: "workflows/details/edit/actions/details/ms-teams/send-channel-message",
        rawFields: [Ember.Object.create({
          label: 'Team',
          key: 'team',
          isDynamicField: true,
          isMandatory: true,
          step: 1,
          isPrimary: true,
          groupName: 'MSTeams Teams',
          entity: 'team',
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: 'Channel',
          key: 'channel',
          isDynamicField: true,
          isMandatory: true,
          step: 2,
          groupName: "MSTeams Channels",
          entity: 'channel',
          transformDataSource: _transformData.transformData,
          transformValue: _transformData.generatePayload
        }), Ember.Object.create({
          label: 'Message',
          key: 'text',
          step: 3,
          isMandatory: true,
          isEditor: true,
          editorId: 'text',
          editorConfig: _editor.LITE_EDITOR_CONFIG
        })]
      });
      var sendDirectMessageFields = actionClass.create({
        stepType: 'send_direct_message',
        application: 'ms-teams',
        color: '#6264A7',
        component: "workflows/details/edit/actions/details/ms-teams/send-direct-message",
        rawFields: [Ember.Object.create({
          label: 'Member',
          key: 'member',
          isDynamicField: true,
          isMandatory: true,
          groupName: 'MSTeams Members',
          entity: 'member',
          lookupUrl: "entity-autocomplete/ms-teams/member/",
          step: 1
        }), Ember.Object.create({
          label: 'Message',
          key: 'text',
          step: 2,
          isMandatory: true,
          isEditor: true,
          editorId: 'text',
          editorConfig: _editor.LITE_EDITOR_CONFIG
        })]
      });
      workflowActionChoices.addObjects([sendChannelMessageFields, sendDirectMessageFields]);
      workflowActionChoices.forEach(function (action) {
        action.displayName = _this.intlService.findTranslationByKey(action.stepType);
        action.eventType = 'action';
      });
      return workflowActionChoices;
    }
  });
});