define("workflows-web/workflows/details/edit/actions/constant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.INTEGRATION_APPS = _exports.ALLOWED_ACTIONS_FOR_REACT_FORMS = _exports.AI_ACTION_FORMS = void 0;
  var AI_ACTION_FORMS = _exports.AI_ACTION_FORMS = ['expand_text', 'summarize_text'];
  var ALLOWED_ACTIONS_FOR_REACT_FORMS = _exports.ALLOWED_ACTIONS_FOR_REACT_FORMS = [{
    application: 'workflows',
    stepType: ['inline_lookup_table', 'transform_text', 'math_operations', 'date_formatter', 'expand_text', 'summarize_text', 'delay', 'test_editor', 'send_email_action_reminder', 'generate_form_link']
  }, {
    application: 'happyfox',
    stepType: ['update_ticket', 'create_task', 'add_subscribers', 'auto_categorize_ticket', 'merge_ticket', 'update_status', 'update_tags', 'update_category', 'update_priority', 'update_assignee', 'update_time_spent', 'get_assets_for_contact', 'merge_contact', 'change_contact', 'add_contact_to_groups', 'auto_tag_ticket', 'copy_assets_from_ticket', 'trigger_satisfaction_survey', 'relate_tickets', 'edit_message', 'get_contact_group_info', 'unlink_asana_task', 'link_asana_task', 'create_task_with_task_template']
  }, {
    application: 'hubspot',
    stepType: ['create_ticket', 'update_ticket', 'get_contact', 'get_company', 'get_deal', 'get_ticket', 'get_custom_object', 'create_associations', 'update_associations', 'remove_associations']
  }, {
    application: 'happyfox-service-desk',
    stepType: ['update_service_request', 'update_service_request_custom_fields']
  }, {
    application: 'happyfox-chatbot',
    stepType: ['end_conversation', 'send_message']
  }, {
    application: 'bamboohr',
    stepType: ['create_employee', 'update_employee', 'add_time_off_request', 'get_employee_details']
  }, {
    application: 'okta',
    stepType: ['activate_user', 'add_user_to_group', 'assign_app_to_user', 'clear_sessions_user', 'deactivate_user', 'expire_password_user', 'get_user_by_login_name', 'reactivate_user', 'suspend_user', 'unsuspend_user', 'unlock_user', 'reset_password_user']
  }];
  var INTEGRATION_APPS = _exports.INTEGRATION_APPS = {
    HAPPYFOX: 'happyfox',
    HAPPYFOX_SERVICE_DESK: 'happyfox-service-desk',
    HAPPYFOX_CHATBOT: 'happyfox-chatbot',
    WORKFLOWS: 'workflows',
    SLACK: 'slack',
    SALESFORCE: 'salesforce',
    JIRA: 'jira',
    ZENDESK: 'zendesk',
    PIPEDRIVE: 'pipedrive',
    SHOPIFY: 'shopify',
    OKTA: 'okta',
    JAMF_PRO: 'jamf-pro',
    GOOGLE_SHEETS: 'google-sheets',
    BAMBOO_HR: 'bamboohr',
    AZURE_DEVOPS: 'azure-devops',
    AZURE_ACTIVE_DIRECTORY: 'azure-active-directory',
    MS_TEAMS: 'ms-teams',
    ASANA: 'asana',
    HUBSPOT: 'hubspot'
  };
});