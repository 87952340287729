define("workflows-web/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.USER_PLAN_STATES = _exports.URL_REGEX = _exports.UNKNOWN_STATE_ALLOWED_ROUTES = _exports.TRIAL_EXPIRED_ALLOWED_ROUTES = _exports.TOP_BAR_HEIGHT = _exports.TEMP_FEATURE_FLAGS = _exports.SUPPORTED_IMAGE_FORMATS = _exports.STAFF_AVATAR_LIST = _exports.SLUG_REPLACE_REGEX = _exports.SLUG_REGEX = _exports.REMOVE_CLASS_ATTR_REGEX = _exports.RELATIVE_TIME_DATE_FORMAT = _exports.PROFILE_PICTURE_MAX_SIZE_IN_MB = _exports.PROFILE_PICTURE_MAX_SIZE = _exports.PAGINATION_MAX_LIMIT = _exports.INPUT_DATE_FORMAT = _exports.EMPTY_DROPDOWN_VALUE = _exports.EMAIL_REGEX = _exports.DEFAULT_USER_IMAGE = _exports.DEFAULT_TIME_FORMAT = _exports.DEFAULT_DATE_FORMAT = _exports.BILLING_PERMISSIONS = _exports.BASE_FONT_SIZE = _exports.BASE_FONT_FAMILY = _exports.AUTOCOMPLETE_MIN_CHARACTER_LENGTH = _exports.API_TIMESTAMP_FORMAT_NON_UTC = _exports.API_TIMESTAMP_FORMAT = _exports.API_DATE_FORMAT = void 0;
  // Date/Time format
  var API_DATE_FORMAT = _exports.API_DATE_FORMAT = 'YYYY-MM-DD';
  var API_TIMESTAMP_FORMAT = _exports.API_TIMESTAMP_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
  var API_TIMESTAMP_FORMAT_NON_UTC = _exports.API_TIMESTAMP_FORMAT_NON_UTC = 'YYYY-MM-DD[T]HH:mm:ss';
  var DEFAULT_DATE_FORMAT = _exports.DEFAULT_DATE_FORMAT = 'MMM D, YYYY';
  var DEFAULT_TIME_FORMAT = _exports.DEFAULT_TIME_FORMAT = 'MMM D, YYYY, h:mm A';
  var INPUT_DATE_FORMAT = _exports.INPUT_DATE_FORMAT = 'MM-DD-YYYY';
  var RELATIVE_TIME_DATE_FORMAT = _exports.RELATIVE_TIME_DATE_FORMAT = 'MMM D, h:mm A';

  // Regexes
  /* eslint-disable no-useless-escape */
  var EMAIL_REGEX = _exports.EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9_](?:[a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  var REMOVE_CLASS_ATTR_REGEX = _exports.REMOVE_CLASS_ATTR_REGEX = 'class\s*?=\s*?(\"|\')(.|\n)*?(\"|\')'; // Regex pattern to remove class attr from given string
  var URL_REGEX = _exports.URL_REGEX = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  var SLUG_REGEX = _exports.SLUG_REGEX = /^[a-z0-9_]+(?:-[a-z0-9]+)*$/;
  /* eslint-disable no-control-regex */
  var SLUG_REPLACE_REGEX = _exports.SLUG_REPLACE_REGEX = /[\x00-\x2F\x3a-\x40\x5B-\x60\x7B-\x7F]+/g;
  /* eslint-enable no-control-regex */
  /* eslint-enable no-useless-escape */

  // CSS Fixes
  var BASE_FONT_SIZE = _exports.BASE_FONT_SIZE = '13px';
  var BASE_FONT_FAMILY = _exports.BASE_FONT_FAMILY = 'Myriad-Pro-Regular,sans-serif';
  var TOP_BAR_HEIGHT = _exports.TOP_BAR_HEIGHT = 150;
  var AUTOCOMPLETE_MIN_CHARACTER_LENGTH = _exports.AUTOCOMPLETE_MIN_CHARACTER_LENGTH = 2;

  // Empty Dropdown value
  var EMPTY_DROPDOWN_VALUE = _exports.EMPTY_DROPDOWN_VALUE = '---------------';

  // Profile picture
  var PROFILE_PICTURE_MAX_SIZE_IN_MB = _exports.PROFILE_PICTURE_MAX_SIZE_IN_MB = 10;
  var PROFILE_PICTURE_MAX_SIZE = _exports.PROFILE_PICTURE_MAX_SIZE = PROFILE_PICTURE_MAX_SIZE_IN_MB * 1024 * 1024;
  var DEFAULT_USER_IMAGE = _exports.DEFAULT_USER_IMAGE = '/assets/images/dummy-staff.png';
  var STAFF_AVATAR_LIST = _exports.STAFF_AVATAR_LIST = [{
    name: 'avatar-1',
    url: '/assets/avatars/avatar-1.png'
  }, {
    name: 'avatar-2',
    url: '/assets/avatars/avatar-2.png'
  }, {
    name: 'avatar-3',
    url: '/assets/avatars/avatar-3.png'
  }, {
    name: 'avatar-4',
    url: '/assets/avatars/avatar-4.png'
  }, {
    name: 'avatar-5',
    url: '/assets/avatars/avatar-5.png'
  }, {
    name: 'avatar-6',
    url: '/assets/avatars/avatar-6.png'
  }, {
    name: 'avatar-7',
    url: '/assets/avatars/avatar-7.png'
  }, {
    name: 'avatar-8',
    url: '/assets/avatars/avatar-8.png'
  }];

  // Pagination
  var PAGINATION_MAX_LIMIT = _exports.PAGINATION_MAX_LIMIT = 50;
  var TEMP_FEATURE_FLAGS = _exports.TEMP_FEATURE_FLAGS = {
    TEMP_ENABLE_MANAGE_PERMISSIONS: 'tempEnableManagePermissions',
    TEMP_USE_REACT_UPDATE_TICKET_FORM: 'tempUseReactUpdateTicketForm',
    TEMP_USE_REACT_AI_ACTIONS: 'tempUseReactAiActions',
    TEMP_ENABLE_TEST_EDITOR: 'tempEnableTestEditor'
  };
  var USER_PLAN_STATES = _exports.USER_PLAN_STATES = {
    ACTIVE: 'active',
    TRIAL_EXPIRED: 'trial_expired',
    TRIAL: 'trial',
    TRIALING: 'trialing',
    UNKNOWN: 'unknown'
  };
  var TRIAL_EXPIRED_ALLOWED_ROUTES = _exports.TRIAL_EXPIRED_ALLOWED_ROUTES = ['workflows.dashboard'];
  var BILLING_PERMISSIONS = _exports.BILLING_PERMISSIONS = 'manage_billing';
  var UNKNOWN_STATE_ALLOWED_ROUTES = _exports.UNKNOWN_STATE_ALLOWED_ROUTES = ['workflows.dashboard'];
  var SUPPORTED_IMAGE_FORMATS = _exports.SUPPORTED_IMAGE_FORMATS = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'tiff'];
});