define("workflows-web/workflows/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TRIGGER_RAW_FIELDS = _exports.MESSAGING_PLATFORMS = _exports.APPS_TO_NOT_MODIFY_PAYLOAD_KEYS = _exports.ALLOWED_MANUAL_TRIGGER_APPLICATION = void 0;
  var APPS_TO_NOT_MODIFY_PAYLOAD_KEYS = _exports.APPS_TO_NOT_MODIFY_PAYLOAD_KEYS = ['salesforce', 'jira', 'azure-active-directory', 'bamboohr'];
  var MESSAGING_PLATFORMS = _exports.MESSAGING_PLATFORMS = ['slack', 'ms_teams'];
  var TRIGGER_RAW_FIELDS = _exports.TRIGGER_RAW_FIELDS = Ember.A([Ember.Object.create({
    key: 'matchAll',
    isArray: true,
    isMandatory: false,
    alwaysSendInPayload: true,
    step: 2,
    childFieldMetaInfo: Ember.Object.create({
      isCondition: true,
      conditionFields: Ember.A(),
      conditionComponent: 'filter-condition',
      addConditionComponent: 'add-filter-condition'
    })
  }), Ember.Object.create({
    key: 'matchAny',
    isArray: true,
    isMandatory: false,
    alwaysSendInPayload: true,
    childFieldMetaInfo: Ember.Object.create({
      isCondition: true,
      conditionFields: Ember.A(),
      conditionComponent: 'filter-condition',
      addConditionComponent: 'add-filter-condition'
    })
  }), Ember.Object.create({
    key: 'enableDelay',
    label: 'Begin workflow execution after a delay',
    isMandatory: false,
    alwaysSendInPayload: false,
    isToggle: true
  }), Ember.Object.create({
    key: 'countdown',
    label: 'Enter the time to delay in minutes:',
    labelHelpText: 'The time to delay should be between 1 and 15 minutes.',
    isMandatory: false,
    alwaysSendInPayload: false,
    isNumber: true,
    minValue: 1,
    maxValue: 15
  })]);
  var ALLOWED_MANUAL_TRIGGER_APPLICATION = _exports.ALLOWED_MANUAL_TRIGGER_APPLICATION = {
    'happyfox': ['ticket_created', 'ticket_updated', 'smart_rule_trigger'],
    'workflows': ['webhook']
  };
});