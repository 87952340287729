define("workflows-web/components/react-components/workflow/details/edit/form-trigger-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vWIFhCxc",
    "block": "{\"symbols\":[\"@formChoices\"],\"statements\":[[11,\"div\"],[4,[38,0],null,[[\"component\",\"formChoices\"],[[32,0,[\"ReactComponent\"]],[32,1]]]],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"react-render\"]}",
    "meta": {
      "moduleName": "workflows-web/components/react-components/workflow/details/edit/form-trigger-wrapper/template.hbs"
    }
  });
});