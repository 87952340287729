define("workflows-web/workflows/details/edit/actions/details/ms-teams/send-direct-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DPXBB0tq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-u-vertical-flex-container hf-workflows_form-group\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,0],[\"step-{index}\"],[[\"index\"],[\"1\"]]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"field\"],[[35,1,[\"model\",\"member\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,1,[\"model\",\"member\",\"value\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"hf-u-vertical-flex-container hf-workflows_form-group\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_header\"],[12],[1,[30,[36,0],[\"step-{index}\"],[[\"index\"],[\"2\"]]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-workflows_form-group_body\"],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"form\",\"key\"],[[35,1],\"text\"]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"form\",\"workflows/details/edit/actions/details/message-field\",\"form-field\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/ms-teams/send-direct-message/template.hbs"
    }
  });
});