define("workflows-web/workflows/apps/details/jira/component", ["exports", "workflows-web/workflows/apps/details/manage-app-form/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    getRawFieldsInfo: function getRawFieldsInfo(isSelfHosted) {
      var intlService = this.get('intlService');
      var formFields;
      if (isSelfHosted === undefined) {
        isSelfHosted = this.app.settings.isSelfHosted;
      }
      if (isSelfHosted) {
        formFields = [Ember.Object.create({
          label: intlService.findTranslationByKey('apps.form-fields.jira-server-url'),
          key: 'serverUrl',
          isText: true
        }), Ember.Object.create({
          label: intlService.findTranslationByKey('apps.form-fields.consumer-key'),
          key: 'consumerKey',
          showCopyLink: true,
          isText: true
        }), Ember.Object.create({
          label: intlService.findTranslationByKey('apps.form-fields.public-key'),
          key: 'publicKey',
          showCopyLink: true,
          isTextarea: true,
          classNames: 'hf-mod-public-key'
        })];
      } else {
        formFields = [Ember.Object.create({
          label: intlService.findTranslationByKey('apps.form-fields.client-id'),
          key: 'clientId',
          isText: true
        }), Ember.Object.create({
          label: intlService.findTranslationByKey('apps.form-fields.client-secret'),
          key: 'clientSecret',
          isPassword: true
        })];
      }
      formFields.unshiftObject(Ember.Object.create({
        label: intlService.findTranslationByKey('apps.form-fields.self-hosted'),
        key: 'isSelfHosted',
        isToggle: true,
        classNames: 'hf-mod-jira-toggle',
        onChange: this.toggleIsHostedApp.bind(this),
        alwaysIncludeInPayload: true,
        defaultValue: false
      }));
      return formFields;
    },
    toggleIsHostedApp: function toggleIsHostedApp(isSelfHosted) {
      var app = this.app;
      app.settings.isSelfHosted = isSelfHosted;
      this.renderForm(this.getRawFieldsInfo(isSelfHosted));
    }
  });
});